import Wrapper from "../wrappers/LandingWrap"

const Footer = () => {
return (
    <Wrapper>
        <footer>
    <h5> © 2025 Designed and Developed by Uliana Sarkisova </h5>
    </footer>
    </Wrapper>
)
}

export default Footer